<script setup type="ts">
    /** vue, o365 */
    import { onMounted, watch, ref, computed } from 'vue'; 
    import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
    import { modalInitialMatrix, modalFinalMatrix } from 'sja.vue.libraries.modules.utilities.js';

    /** mobile */
    import { MContainer, MCard } from 'o365-mobile';
    //import { switcher } from 'sja.vue.mob.TasksMobile.vue';

    const props = defineProps({
        sjaID: Number,
        readOnly: Boolean,
        SJATask_ID: String,
        switch: String
    });

    onMounted(() => {
        dsHazards.load();
        dsProbabilitiesLkp.load();
        dsConsequencesLkp.load();
        dsHazardsModalFinal.load();
        dsHazardsModalInitial.load();
    });

    let dsHazards = getOrCreateDataObject({
        id: `dsTasksHazards_${props.SJATask_ID}`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true, 
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'SJA_ID', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', }
        ],
        whereClause: `SJATask_ID = ${props.SJATask_ID}`,
    });

    const dsConsequencesLkp = getOrCreateDataObject({
        id: `dsConsequencesLkp`,
        maxRecords: -1,
        viewName: 'aviw_SJA_Consequences',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Value', },
            { name: 'Description', },
            { name: 'ValueAndName', },
            { name: 'NameAndDescription', },
            { name: 'ValueAndNameAndDescription', }
        ],
    });

    const dsProbabilitiesLkp = getOrCreateDataObject({
        id: `dsProbabilitiesLkp`,
        maxRecords: -1,
        viewName: 'aviw_SJA_Probabilities',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Value', },
            { name: 'Description', },
            { name: 'ValueAndName', },
            { name: 'NameAndDescription', },
            { name: 'ValueAndNameAndDescription', }
        ],
    });

    const dsHazardsModalInitial = getOrCreateDataObject({
        id: `dsTasksHazardsModalInitial`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        selectFirstRowOnLoad: true,
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'SJA_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', }
        ],
    });

    const dsHazardsModalFinal = getOrCreateDataObject({
        id: `dsTasksHazardsModalFinal`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        selectFirstRowOnLoad: true,
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'SJA_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', },
            { name: 'ProbabilityValueID', },
            { name: 'ConsequenceValueID', },
            { name: 'FinalProbabilityValueID', },
            { name: 'FinalConsequenceValueID', }
        ],
    });

    
    function onModalShown(modal){
        if(modal !== null && modal !== undefined){
            let _modal = modal.value.modal._element;
            _modal.style.zIndex = '10000';
            _modal.style.position='absolute';
        }else{
            document.getElementById('CreateHazardModal').style.zIndex = '10000';
            document.getElementById('CreateHazardModal').style.position='absolute';
        }
    }

    function openModal(hazard, modaltype) {
        const dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`);
        const dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`);
        dsHazardsModalInitial.recordSource.whereClause = `ID = ${hazard.ID}`
        dsHazardsModalFinal.recordSource.whereClause = `ID = ${hazard.ID}`
        if (modaltype === "Initial") {
            dsHazardsModalInitial.load().then(() => {
                modalInitialMatrix.value.show().then(()=> {
                    onModalShown(modalInitialMatrix);
                });
            });
        }
        if (modaltype === "Final") {
            dsHazardsModalFinal.load().then(() => {
                modalFinalMatrix.value.show().then(()=>{
                    onModalShown(modalFinalMatrix);
                });
            });
        }
        
    }


    dsHazards.window = dsHazards;
    let Name = ref(null);
    let ref_newHazard = ref(null);

    function setIndex(e){
        if(e !== undefined && e.index !== null){
            dsHazards.setCurrentIndex(e.index);
        }
    }
    function createNewHazard(){
        let _Name = Name.value;
        
        if(_Name !== undefined || _Name !== null){
            dsHazards.createNew({Name: _Name, SJATask_ID: props.SJATask_ID}, false);
            dsHazards.load();
            bootstrap.Modal.getInstance(document.querySelector('#CreateHazardModal')).hide();
            resetAndDismiss();
        }else{
            alert($t(`Please fill in all fields`), ToastType.Danger, { autohide: true, delay: 3000 });
        }
        
    }
    function resetAndDismiss(){
        Name.value = null;
    }

    let disableButton = computed (() =>{
        return dsHazards.current?.hasChanges;
    });

    async function saveChanges(){
        await dsHazards.save();
    }

    function discard(pIndex){
        if(pIndex !== undefined && pIndex !== null){
            dsHazards.cancelChanges(pIndex);
        }
    }

</script>

<style>

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .btn-new{
        border-radius: 2.0rem;
        overflow: hidden;
        background-color: #4682B4;
        color: white;
    }



</style>

<template>
    <MContainer class="d-flex flex-column mb-3">
        <div class="p-3 mb-auto">
            <div class="row g-2">
                <template v-for="item in dsHazards.data">
                    <div class="d-flex" v-if="!item.isNewRecord">
                        <div class="flex-grow-1">
                            <MCard> 
                                <span @click="switcher = 'Actions'"> {{ item.Name }} </span> 
                                <div class="btn btn-sm float-end dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots-vertical"></i>
                                </div>
                                <div class="dropdown" style="font-size: 17px;">
                                    <ul class="dropdown-menu">
                                        <li class="d-flex">
                                            <a class="dropdown-item flex-grow-1" data-bs-toggle="offcanvas" data-bs-target="#showEdit" aria-controls="showEdit" @click="setIndex(item)"> {{ $t('Edit') }} 
                                                <i class="bi bi-pencil-square float-end "></i>
                                            </a>
                                        </li>
                                        <li class="d-flex">
                                            <a class="dropdown-item flex-grow-1" @click="!props.readOnly ? openModal(item, 'Final') : ''"> {{ $t('Final risk assessment') }} 
                                                <i :title="$t('Edit the final risk assessment.') + '\n' 
                                                            + $t('Probability') + ': ' + (item.FinalProbabilityNameAndDesc ? item.FinalProbabilityNameAndDesc : ' ') + '\n' 
                                                            + $t('Consequence') + ': ' + (item.FinalConsequenceNameAndDesc ? item.FinalConsequenceNameAndDesc : ' ')" 
                                                    type="button"
                                                    :style="{ 'color': item.FinalColor, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                                    class="fs-5 bi bi-square-fill float-end"></i>
                                            </a>
                                        </li>
                                        <li class="d-flex">
                                            <a class="dropdown-item flex-grow-1" @click="!props.readOnly ? openModal(item, 'Initial') : ''"> {{ $t('Initial risk assessment') }} 
                                                <i :title="$t('Edit the initial risk assessment.') + '\n' 
                                                            + $t('Probability') + ': ' + (item.ProbabilityNameAndDesc ? item.ProbabilityNameAndDesc : ' ') + '\n' 
                                                            + $t('Consequence') + ': ' + (item.ConsequenceNameAndDesc ? item.ConsequenceNameAndDesc : ' ')" 
                                                    type="button"
                                                    :style="{ 'color': item.Color, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                                    class="fs-5 bi bi-square-fill float-end"></i>
                                            </a>
                                        </li>
                                        <li class="d-flex">
                                            <a class="dropdown-item flex-grow-1 text-danger"> {{ $t('Delete') }} 
                                                <i class="bi bi-x float-end"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </MCard>    
                        </div>
                    </div>
                </template>    
                <div class="offcanvas offcanvas-end" tabindex="-1" id="showEdit" aria-labelledby="offcanvasL" @hidden="dsHazards.unsetCurrentIndex()">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasL">{{ $t('Editing current hazard:') }}</h5>
                        <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body" >
                        <div class="mb-3">
                            <label for="IName" class="form-label">{{ $t('Name') }}</label>
                            <input id="IName" class="form-control" v-model="dsHazards.current.Name"/>
                        </div>
                        <div class="mt-5">
                            <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="saveChanges()">  {{ $t('Save') }}  </button>
                            <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="discard(dsHazards.current.index)"> {{ $t('Cancel') }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OModal id="CreateHazardModal" name="NewHazard-modal" ref="ref_newHazard" @show="onModalShown()">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"> {{$t('Create New Hazard')}} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="NName" class="form-label">{{ $t('Name') }}</label>
                            <input id="NName" class="form-control" v-model="Name"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary mx-1" @click="createNewHazard()"> {{ $t('Create') }}  </button>
                        <button type="button" class="btn btn-primary mx-1" data-bs-dismiss="modal" @click="resetAndDismiss()"> {{ $t('Cancel') }} </button>
                    </div>
                </div>
            </div>
        </OModal>
        <div class="d-flex p-3" style="margin-left: auto;">
            <a class="btn flex-grow-1 btn-new" v-target:NewHazard-modal>
                <i class="bi bi-plus fs-2 float-end"></i>
            </a>      
        </div>                    
    </MContainer>

</template>